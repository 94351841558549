<template>
    <div>
        <ul>
            <li v-for="user in users.filter(u => u.executant == 'O')" :key="user.id">
                <span :style="clr(user.code, iduser == user.id)" @click="choice(user)">
                    <i class="fa fa-address-book"></i>
                    {{ user.prenom }}
                </span>
                &nbsp;
            </li>
        </ul>
        <FullCalendar :options='calendarOptions' />
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { constrainPoint } from '@fullcalendar/core/internal';
import store from "../store";
import { getPrefix, executeApi, selectApi } from "../lib/api.js";

// https://fullcalendar.io/docs/event-object
export default {
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data: function () {
        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                initialView: 'dayGridMonth',
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                eventAdd: this.handleEventAdd,
                eventChange: this.handleEventChange,
                eventRemove: this.handleEventRemove,
                events: [
                ]
            },
            users: [],
            count: 0,
            isExecutant: false,
            isResponsable: false,
            iduser: 0,
            bg: ''
        }
    },
    methods: {
        choice(user) {
            this.iduser = user.id;
            this.bg = user.code;
        },
        clr(code, actif) {

            if (!actif) {
                return "background: " + code + "; color: black; padding: 5px; border-radius: 5px;"
            } else {
                return "background: " + code + "; color: white; padding: 5px; border-radius: 5px;"
            }
        },
        getUserid(code) {
            let user = this.users.find(u => u.code == code)
            return user.id
        },
        handleEventAdd() {
        },
        handleEventChange(selectInfo) {
            console.log('event changed')
            let sql = "UPDATE agenda SET debut='" + selectInfo.event.startStr + "',fin='" + selectInfo.event.endStr + "',objet='" + selectInfo.event.title + "',day=" + (selectInfo.event.allDay ? 1 : 0) + " WHERE id=" + selectInfo.event.id;
            executeApi(this, sql).then(
                (response) => {
                },
                (response) => {
                }
            );

        },
        handleEventRemove() {
        },
        handleDateSelect(selectInfo) {

            if (!this.isResponsable) return
            if (this.iduser == 0) return

            let title = prompt("Saisir l'objet")
            let calendarApi = selectInfo.view.calendar

            calendarApi.unselect() // clear date selection

            if (title) {
                console.log(selectInfo.startStr, selectInfo.endStr)


                let sql = "INSERT INTO agenda SET iduser=" + this.iduser + ",debut='" + selectInfo.startStr + "',fin='" + selectInfo.endStr + "',objet='" + title + "',color='" + this.bg + "',day=" + (selectInfo.allDay ? 1 : 0);
                executeApi(this, sql).then(
                    (response) => {
                        calendarApi.addEvent({
                            id: response.data.last,
                            title,
                            start: selectInfo.startStr,
                            end: selectInfo.endStr,
                            allDay: selectInfo.allDay,
                            backgroundColor: this.bg
                        })
                    },
                    (response) => {
                    }
                );

            }
        },
        handleEventClick(clickInfo) {
            if (!this.isResponsable) return

            if (confirm("Voulez-vous détruire l'évenement " + clickInfo.event.title + " ?")) {
                let sql = "DELETE FROM agenda WHERE id=" + clickInfo.event.id;
                executeApi(this, sql).then(
                    (response) => {
                    },
                    (response) => {
                    }
                );
                clickInfo.event.remove()
            }
        },
        handleEvents(events) {
        },
        getUsers() {
            let sql = "SELECT id,nom,prenom,executant,code,idinfo FROM cgbcommun.user";
            selectApi(this, sql).then(
                (response) => {
                    this.users = response.data.result;
                    this.count = response.data.count;
                    this.users.forEach(item => {
                        if (item.id == store.state.idUser) {
                            this.isExecutant = item.executant == 'O' ? true : false;
                            this.isResponsable = item.idinfo == 1 ? true : false;
                        }
                    });
                    this.getEvents();
                },
                (response) => {
                    this.count = 0;
                    this.users = [];
                }
            )
        },
        getEvents() {

            let sql = "SELECT id,iduser,debut,fin,day,objet,color FROM agenda"
            selectApi(this, sql).then(
                (response) => {
                    let events = []
                    response.data.result.forEach(item => {
                        let event = {
                            id: item.id,
                            title: item.objet,
                            start: new Date(item.debut),
                            end: new Date(item.fin),
                            allDay: item.day == 1 ? true : false,
                            backgroundColor: item.color
                        }
                        events.push(event)
                    })
                    console.log(events)
                    this.calendarOptions.events = events
                },
                (response) => {
                }
            )
        }
    },
    mounted() {
        if (store.state.typeUser != 3) {
            this.$router.push({ name: "login" });
        }
        this.getUsers();
    }
}
</script>

<style lang="scss" scoped>
ul li {
    display: inline;
    font-size: 12px;
}
</style>